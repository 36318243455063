body {
    /* padding-bottom: 1rem; */
    color: #5a5a5a;
}

.main-nav.scrolled {
    background-color: rgba(80, 80, 80, 0.55);
}

.main-nav.inner-page-nav {
    background-color: rgba(128, 128, 128, 0.4);
}


.main-nav .navbar-text,
.main-carousel.carousel h1,
.main-carousel.carousel p {
    color: white;
    text-shadow: rgba(0, 0, 0, 0.65) 5px 5px 5px
}

.main-carousel.carousel .carousel-item.active {
    display: flex;
    align-items: center;
}

.main-carousel.carousel .carousel-item .carousel-caption {
    bottom: auto;
}

.main-nav .navbar-toggler-icon {
    background-image: url(assets/images/collapse.svg) !important;
    width: 1em;
    height: 1em;
}

.main-nav button.navbar-toggler {
    border-color: rgba(255, 255, 255, 0.4);
    border-style: none solid;
    border-width: 1px;
    border-image: none 100% / 1 / 0 stretch;
    border-radius: 0px;
}

.main-nav .navbar-brand img {
    display: block;
    float: left;
    margin-right: 10px;
}

.main-nav .navbar-brand span {
    font-weight: bold;
    color: white;
}

.carousel.main-carousel .carousel-learn-more {
    text-align: left;
    border-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    border-radius: 100px;
    box-shadow: none;
    overflow: hidden;
    color: white;
    text-decoration: none;
    padding: 20px 40px;
    margin: 20px;
    display: inline-block;
    font-weight: bold;
    text-shadow: none;
    font-size: 0.9em;
    transition: 0.3s all ease-in-out;
}

.carousel.main-carousel .carousel-learn-more:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.07);
}

.carousel.main-carousel {
    height: 100vh;
}

.carousel.main-carousel .carousel-inner {
    height: 100%;
}

.carousel.main-carousel .carousel-caption {
    z-index: 10;
}

.carousel.main-carousel .carousel-item {
    overflow: hidden;
}

.carousel.main-carousel .carousel-item img {
    object-fit: cover;
    object-position: center;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.featurette-divider {
    margin: 2rem 0;
}

.carousel-caption a {
    color: white;
    font-weight: 600;
}

@media (min-width: 40em) {

    .carousel-caption p {
        margin-bottom: 1.25rem;
        font-size: 1.25rem;
        line-height: 1.4;
    }
}

.news .card {
    border-radius: 0;
    width: 20rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.5s;
}

.white-bg {
    background-color: white !important;
}

.news .card .card-img-top {
    border-radius: 0;
    height: 170px;
}

.news .card:hover {
    box-shadow: none;
}

.carousel-highlights .carousel-item {
    height: 475px;
    overflow: hidden;
}

.carousel-highlights .carousel-caption {
    background-color: rgba(80, 80, 80, 0.5);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.carousel-highlights .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main-footer {
    display: flex !important;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
}

.full-screen-map {
    height: 100vh;
    width: 100%;
}

.full-screen-map.with-space {
    height: calc(100vh - 125px);
    width: 100%;
}

.ol-zoom {
    top: auto !important;
    bottom: .5em;
}

.full-screen-map .ol-zoom {
    bottom: .5em;
    right: .5em;
    left: unset;
    top: unset;
}

.full-screen-map .ol-zoomslider {
    bottom: 4.5em;
    right: .5em;
    left: unset;
    top: unset;
}

.form-signin {
    max-width: 330px;
    padding: 15px;
}

.form-signin-container {
    display: flex;
    align-items: center;
    height: calc(100vh - 130px);
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="text"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.full-screen-container {
    height: calc(100vh - 130px);
}

.query-map {
    height: 100%;
}

.query-form {
    z-index: 100;
    background-color: white;
    padding: 20px;
    top: 160px;
    left: 30px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: absolute;
    display: flex;
    max-width: 690px;
    min-width: min(calc(100vw - 60px), 365px);
    width: 35vw;
    max-height: 600px;
}

.query-form .form-container {
    width: 100%;
    max-height: 452px;
    overflow-y: auto;
}

.menu-padding {
    margin-top: 130px;
}

.news-more-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

table tbody tr.selected {
    background-color: #acbad4;
}

.is-invalid .react-select__control {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.is-valid .react-select__control {
    border-color: #198754;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.ol-control.ol-layers-control {
    display: none;
}

.square:before {
    content: "";
    float: left;
    padding-top: 100%;
    /* initial ratio of 1:1*/
}

.navbar-toggler:focus {
    border: none !important;
    box-shadow: none !important;
}

.project-data-col {
    cursor: default;
    color: black;
    white-space: nowrap;
}

.project-data-col-text {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    flex-direction: column;
}

.project-data-col-text h4 {
    font-family: 'Arial Black', Gadget, sans-serif;
}

.carousel-highlights-tab {
    opacity: 0.5;
    cursor: pointer;
}

.carousel-highlights-tab:not(:last-child) {
    border-right: 1px solid #eee;
}

.carousel-highlights-tab.selected {
    opacity: 1;
    border-bottom: 2px solid rgb(108, 198, 240);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.modal-header .btn-fullscreen {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .375rem;
    opacity: .5;
}

.modal-header .btn-exit-fullscreen {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .375rem;
    opacity: .5;
}

.modal-title {
    margin-right: auto;
}

.modal-header .btn-close {
    margin-left: 0 !important;
}

div.rdt_TableCol_Sortable div {
    white-space: pre-line !important;
    overflow: unset !important;
    text-align: center;
}

.wtp-preview h5,
.wtp-preview h6 {
    color: #009fff;
}

.dropdown-selection .dropdown-menu {
    height: fit-content;
    max-height: 50vh;
    overflow: clip auto;
}

.dropdown-selection .dropdown-item label {
    width: 100%;
}

.current-snapshot {
    color: red !important;
    display: block;
    background-color: white;
    position: absolute;
    top: 0;
    left: 800px;
    padding: 10px;
    font-weight: bold;
}

.translations-table .rdt_Table {
    width: calc(100vw - 24px) !important;
}